import { MediaService } from "@/game/xstate/services";
// import store from "@/store";

import warmup from "../warmup";

// const SCROLLLER_ID = "https://www.scrolller.online/mobile/list/girls";
const SCROLLLER_ID =
  "https://wmptengate.com/pu/?target=pulfc&psid=scrolller&pstool=300_62&psprogram=cbrnd&campaign_id=128124&categoryName=girl&cobrandId=258376&site=wl3&width=1100&height=764&cap=24&ms_notrack=1&subAffId=%7BSUBAFFID%7D";

export function openScrollerAd() {
  const handle = window.open(SCROLLLER_ID, "_blank");

  if (handle) {
    // @ts-expect-error global
    if (window.umami) {
      // @ts-expect-error global
      window.umami.track("successfully opened scroller ad");
    }
  } else {
    // @ts-expect-error global
    if (window.umami) {
      // @ts-expect-error global
      window.umami.track("failed to open scroller ad");
    }
  }
}

export const showScrollerAd = async (showWarmup?: boolean) => {
  MediaService.pause();

  // store.game.ad = SCROLLLER_ID;

  const showAdButton = async () => {
    openScrollerAd();

    const continueButton = async () => {
      // store.game.ad = null;
      MediaService.play();

      if (showWarmup) {
        return warmup();
      }
    };
    continueButton.label = "Resume Game";

    return [continueButton];
  };
  showAdButton.label = "Show Ad";

  return [showAdButton];
};
showScrollerAd.label = "Ad";

export const showScrollerWarmupAd = async () => {
  return showScrollerAd(true);
};
