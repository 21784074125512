/* eslint-disable react/jsx-no-target-blank */
import { Box, Paper, Typography } from "@material-ui/core";
import styled from "styled-components/macro";

const Container = styled(Paper)`
  background-color: #ffff8d;
  margin-top: 2rem;
  margin: 1rem;
`;

export function SupportSiteBanner() {
  return (
    <Container elevation={3}>
      <Box p={2}>
        <Typography>
          Ads help support this free product, if you would like to enjoy an ad
          free experience sign up at{" "}
          <a target="_blank" href="https://www.fapchallenger.com">
            https://fapchallenger.com
          </a>
        </Typography>
      </Box>
    </Container>
  );
}
