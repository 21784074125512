// import * as React from "react";
import { Button, Typography } from "@material-ui/core";
import styled from "styled-components/macro";

import aiIconPng from "@/assets/images/alpha/ai-icon.png";
import optionsIconPng from "@/assets/images/alpha/options-icon.png";
import scrollerIconPng from "@/assets/images/alpha/scroller-icon.png";
import sexToyIconPng from "@/assets/images/alpha/sex-toy-icon.png";
import fiAlphaLogoPng from "@/assets/images/alpha/fi-alpha-logo.png";
import newPng from "@/assets/images/alpha/new.png";
import theme from "@/theme";

import { Group } from "./Group";

const FIAlphaContainer = styled.div`
  padding: 32px;
  margin: 16px;
  background: radial-gradient(50% 50% at 50% 50%, #fec8cf 0%, #fff0f2 100%);
  border-radius: 10px;

  max-width: 1200px;

  @media screen and (${theme.breakpoint.tablet.down}) {
    padding: 16px;
  }
`;

const BentoContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 1rem;

  @media screen and (${theme.breakpoint.tablet.down}) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }
`;

const BentoBox = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 48px rgba(253, 195, 204, 0.46);
  border-radius: 8px;
  padding: 2rem;
  display: flex;
  gap: 16px;
  @media screen and (${theme.breakpoint.tablet.down}) {
    padding: 1rem;
  }
`;

const BentoTitle = styled.h3`
  border-radius: 10px;
  background: white;
`;

const BentoDescription = styled.h3`
  border-radius: 10px;
  background: white;
  font-size: 12px;
  color: #8c8c8c;
`;

const AlphaIcon = styled.img`
  background: black;
  border-radius: 8px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

type FeatureBoxProps = {
  icon: string;
  heading: string;
  body: string;
};

// const mobildMediaQuery = window.matchMedia("(max-width: 769px)");

// function isMobileViewPort() {
//   return mobildMediaQuery.matches;
// }

function FeatureBox({ icon, heading, body }: FeatureBoxProps) {
  return (
    <BentoBox>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <AlphaIcon alt="" src={icon} />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          gap: "0.25rem",
        }}
      >
        <BentoTitle>
          <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
            {heading}
          </Typography>
        </BentoTitle>
        <BentoDescription>
          <Typography variant="body2">{body}</Typography>
        </BentoDescription>
      </div>
    </BentoBox>
  );
}

export function FapInstructorAlphaBanner() {
  // const [isMobile, setIsMobile] = React.useState(isMobileViewPort());
  //
  // React.useLayoutEffect(() => {
  //   const handleChangeEvent = () => {
  //     setIsMobile(isMobileViewPort());
  //   };
  //
  //   window.addEventListener("resize", handleChangeEvent);
  //
  //   return () => {
  //     window.removeEventListener("resize", handleChangeEvent);
  //   };
  // }, [setIsMobile]);

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <FIAlphaContainer>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "1rem",
            flexDirection: "column",
            gap: "0.75rem",
          }}
        >
          <img alt="" src={fiAlphaLogoPng} />
          <div
            style={{ display: "flex", alignItems: "center", gap: "0.75rem" }}
          >
            <img alt="new" src={newPng} />
            <Typography variant="h5" style={{ fontWeight: "bold" }}>
              Fap Instructor
            </Typography>
          </div>
        </div>
        <BentoContainer>
          <FeatureBox
            icon={aiIconPng}
            heading="AI-Generated Instructor Voices"
            body="Experience the guidance of three unique AI-generated voices. Let them lead you to new heights of pleasure."
          />
          <FeatureBox
            icon={optionsIconPng}
            heading="Fully Customizable Game Generator"
            body="Explore a new and improved game generator that has a staggering amount of options.  Including butt stuff, CEI, edging, CBT and so much more."
          />
          <FeatureBox
            icon={sexToyIconPng}
            heading="Sex Toy Integration"
            body="Enhance your experience with seamless support for industry-leading sex toys, including TheHandy and Lovense."
          />
          <FeatureBox
            icon={scrollerIconPng}
            heading="Porn Scroller"
            body="Scroll through thousands of randomly selected images and videos based off of your preferences."
          />
        </BentoContainer>
        <div
          style={{
            display: "flex",
            gap: "1rem",
            flexWrap: "wrap",
            justifyContent: "center",
            marginTop: "2rem",
          }}
        >
          <Button
            target="_blank"
            color="secondary"
            variant="contained"
            href="https://www.patreon.com/fapinstructor"
            rel="noreferrer"
          >
            Join Now
          </Button>
          <Button
            target="_blank"
            color="secondary"
            variant="outlined"
            href="https://fapchallenger.com"
            rel="noreferrer"
          >
            Open Alpha Site
          </Button>
        </div>
      </FIAlphaContainer>
    </div>
  );

  return (
    <Group
      title={
        <Typography variant="h6" color="secondary">
          🎉 Fap Instructor Alpha
        </Typography>
      }
      style={{ background: "#333", color: "#DDD" }}
    >
      <Typography>
        TESTThis site is getting old, so I'm building it from the ground up but
        on steroids.
      </Typography>
      <ul>
        <li>
          <Typography>
            🤯 A ridiculous amount of configuration options available to
            generate games.
          </Typography>
        </li>
        <li>
          <Typography>
            💪 A game builder where you can construct custom built games from
            scratch using a powerful game engine.
          </Typography>
        </li>
        <li>
          <Typography>
            👁️ Immersive porn scroller, chat bot for encouragement, and so much
            more to come.
          </Typography>
        </li>
      </ul>
      <Typography>
        Currently the alpha is{" "}
        <span style={{ fontWeight: "bold", color: "hotpink" }}>
          only available to Patreons ❤️
        </span>
        ! If you want to support the developer, provide direct feedback and gain
        access to the site check us out using the buttons below!
      </Typography>
      <div
        style={{
          display: "flex",
          gap: "1rem",
          flexWrap: "wrap",
        }}
      >
        <Button
          target="_blank"
          color="secondary"
          variant="contained"
          href="https://www.patreon.com/fapinstructor"
          rel="noreferrer"
        >
          Sign up on Patreon
        </Button>
        <Button
          target="_blank"
          color="secondary"
          variant="contained"
          href="https://fapchallenger.com"
          rel="noreferrer"
        >
          Open Alpha Site
        </Button>
      </div>
    </Group>
  );
}
