import * as React from "react";
import { Box, Button, Typography } from "@material-ui/core";
import styled from "styled-components/macro";

import { Group } from "@/components/Group";
import { Tags } from "@/components/Tags";
import { Stack } from "@/components/Templates";
import { FapInstructorAlphaBanner } from "@/components/FapInstructorAlphaBanner";

import { Game } from "../types/Game";

export type GameSummaryCardProps = {
  game: Game;
  children: React.ReactNode;
};

const ContentContainer = styled(Stack)`
  --space: 0.5rem;
`;

const referrer = "https://jerkofftocelebs.com/";

export default function GameSummaryCard({
  game,
  children,
}: GameSummaryCardProps) {
  const [showAlphaBanner, setShowAlphaBanner] = React.useState(
    document.referrer === referrer
  );

  return showAlphaBanner ? (
    <Box overflow="auto" textAlign="center" style={{ marginTop: "2rem" }}>
      <Button
        color="primary"
        variant="contained"
        size="large"
        onClick={() => {
          setShowAlphaBanner(false);
        }}
        style={{ marginBottom: "1rem" }}
      >
        Continue to Game
      </Button>
      <div style={{ textAlign: "left" }}>
        <FapInstructorAlphaBanner />
      </div>
    </Box>
  ) : (
    <Group title="Game Summary">
      <ContentContainer>
        <Typography variant="h6" gutterBottom>
          {game.title}
        </Typography>
        <Typography variant="body1">Game Duration</Typography>
        <Typography variant="body2" gutterBottom>
          {game.config.gameDuration.min}-{game.config.gameDuration.max} min
        </Typography>
        {game.tags && game.tags.length > 0 && (
          <>
            <Typography variant="body1">Tags</Typography>
            <Tags tags={game.tags} />
          </>
        )}
      </ContentContainer>
      {children}
    </Group>
  );
}
